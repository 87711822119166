@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto:900');

.swal2-popup {
  font-family: 'Roboto' !important;
}

.nav-tabs.nav-bordered {
    border-bottom: 2px solid rgba(152,166,173,.2);
}

.accordian-header{
  border: 1px solid #333;
  margin: 5px;
  padding: 10px;
}

/* .centerTable{
  display: table;
  height: calc(100vh - 400px);
} */

/* .centerTextOnScreen{
  overflow: hidden;
  height: calc(100vh - 400px);
  margin-top: 200px;
  margin-bottom: 200px;
  width: 100%;
} */

.if-number-correct-item{
  border: 1px solid #333;
  margin: 5px;
  padding: 10px;
}

.nav-tabs.nav-bordered li a.active {
    border-bottom: 2px solid #727cf5;
}

.mb-3, .my-3 {
    margin-bottom: 1.5rem!important;
}

link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.bg-dark-light {
  background-color: #e3eaef!important;
}

.form-wizard-header {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
}

.nav-tabs.nav-bordered li a {
    border: 0;
    padding: .625rem 1.25rem;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.nav-link:focus, .nav-link:hover {
    text-decoration: none;
}

.admin-wrapper {
  position: relative;
  top: 0;
  height: 100vh;
  overflow-y: scroll;
  background-color:#fafbfe;
  font-family: 'Roboto';
}

.admin-main-panel {
  position: relative;
  float: right;
  top: 75px;
  width: calc(100% - 260px);
  color: #fff;
  font-family: 'Roboto';
}

.admin-header-text{
  margin-bottom: 20px;
  text-align: left;
  color: black;
  margin-top: 46px;
  margin-left: 20px;
  font-weight: bolder;
  font-family: 'Roboto';
}

.nav-item{
  font-family: 'Roboto' !important;
}

.btn-outline-danger{
  color: red !important;
}

.btn-outline-danger:hover{
  color: white !important;
}


.admin-subheader-text{
  margin-top:-20;
  font-size: 10px;
  margin-left:20px;
  color: grey;
  font-family: 'Open Sans';
}

td{
  padding:20px;
  padding-left: 0;
  font-family: 'Roboto';
}

th{
  padding-right: 20px;
  font-family: 'Roboto';
}

.btn-primary {
  -webkit-box-shadow: 0 2px 6px 0 rgba(114,124,245,.5);
  font-weight: 900;
  font-family: 'Roboto';
  box-shadow: 0 2px 6px 0 rgba(114,124,245,.5);
}

.btn-info.focus, .btn-info:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(57,175,209,.5);
    font-weight: 900;
    font-family: 'Roboto';
    box-shadow: 0 0 0 .2rem rgba(57,175,209,.5);
}

.btn-info {
    -webkit-box-shadow: 0 2px 6px 0 rgba(57,175,209,.5);
    font-weight: bold;
    font-family: 'Roboto';
    box-shadow: 0 2px 6px 0 rgba(57,175,209,.5);
}

.header-title {
    margin-bottom: .5rem;
    text-transform: uppercase;
    letter-spacing: .02em;
    font-size: 1.5rem;
    margin-top: 0;
    font-family: 'Roboto';
}

.mb-3, .my-3 {
    margin-bottom: 1.5rem !important;
}

.top-button-styles{
  float: right;
  padding: 20px;
  font-family: 'Roboto';
}

.backgroundImageHere {
  margin-top: -50px;
  height: 100vh;
  background:url('../../styles/images/background.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
}

.export-button-styles{
  border: 1px solid black;
  padding: 5px;
  font-size: 20px;
  margin-right: 20px;
  color: #fff;
  background-color: #87CEEB;
  font-family: 'Roboto';
}

.refresh-button-styles{
  padding: 5px;
  font-size: 20px;
  font-family: 'Roboto';
}


.admin-main-panel h1 {
  margin-bottom: 46px;
  text-align: center;
  margin-top: 36px;
  text-transform: uppercase;
  font-size: 5em;
  font-family: 'Roboto';
  color: #fff;
  text-shadow: 6px 6px 0px rgba(0,0,0,0.75);
}

.admin-main-panel h2 {
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.7em;
  font-weight: bold;
  font-family: 'Roboto';
  color: #fff;
  text-shadow: 6px 6px 0px rgba(0,0,0,0.75);
}

.admin-grid-container {
  display: grid;
  margin-left: 20px;
  margin-right: 20px;
  grid-gap: 10px;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  grid-auto-rows: minmax(100px, auto);
  grid-auto-flow: row;
  font-family: 'Roboto';
  color: #444;
}

.admin-grid-container.admin-home {
  grid-auto-rows: minmax(100px, 135px);
  font-family: 'Roboto';
}

.admin-grid-container.two-columns {
  grid-template-columns: repeat(2, minmax(200px, 1fr));
}

.admin-grid-container.three-columns {
  grid-template-columns: repeat(3, minmax(100px, 1fr));
}

.admin-grid-container.four-columns {
  grid-template-columns: repeat(4, minmax(100px, 1fr));
}

.card-styles {
  background-color: #eee;
  font-family: 'Roboto';
  color: #000;
  border: none;
}

.card{
  border: none !important;
  box-shadow: 0 0 35px 0 rgba(154,161,171,.15) !important;
}

.help-text {
  font-size: 20px;
  font-family: 'Roboto';
}

.help-links{
  margin: 2px;
  margin-left: 20px;
  padding:0;
  color: black;
  font-style: underline;
  font-family: 'Roboto';
}

.value-text {
  font-size: 40px;
  /* font-weight: 700; */
}

.image-button{
  color:white;
  background-color: red;
  display: block;
  margin:auto
}

.admin-form-box{
  color: #000;
  padding: 20px;
  border-radius: 4px;
  /* margin-bottom: 50px; */
  margin-bottom: 15px;
  margin-left: 20px;
  margin-top: 20px;
  text-align: left;
}

.question-box {
  border: 1px solid #000;
  background-color: #eee;
  color: #000;
  padding: 20px;
  border-radius: 4px;
  /* margin-bottom: 50px; */
  margin-bottom: 15px;
  text-align: left;
}

.question-box.mar-b-50 {
  margin: 0 auto 50px;
}

.question-box.mar-b-30 {
  margin-bottom: 30px !important;
}

.question-form {
  margin-bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.question-box-current {
  border: 2px dashed #898989;
}

.question-box ul, .question-box ol {
  padding: 0;
  margin: 15px 0;
}

.question-box li {
  list-style-type: none;
  background: #fff;
  padding: 5px 10px;
  margin: 5px 0;
  border: 1px solid #000;
  border-radius: 4px;
  color: #000;
  text-align: left;
  font-weight: 500;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (min-width: 1200px) {
  .admin-grid-container {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }
  .admin-grid-container.admin-home {
    max-width: 700px;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    margin: auto;
  }
}

@media (max-width: 767px) {
  .admin-grid-container, .admin-grid-container.two-columns {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
}

.question-img {
  margin: 15px 0 !important;
}

.question-img img {
  border: 1px solid #000;
  min-height: 150px;
  max-height: 150px;
}


.btn.btn-default.btn-admin {
  margin: 5px 5px 5px 0;
  background: linear-gradient(#292929, #242424);
  border-top: 1px solid rgba(255,255,255,0.1);
  border-bottom: 1px solid black;
  text-decoration: none;
  /* height: 100%; */
  line-height: 35px;
  color: #bbb;
  text-transform: uppercase;
  font-weight: 900;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  padding: 2px 15px;
}

.btn.btn-default.btn-admin:hover {
  cursor: pointer;
  color: #fff;
}

.btn-admin:not([disabled]):not(.disabled):active, .btn-admin:not([disabled]):not(.disabled).active {
  background: black;
}

.question-box label, .question-box p {
  font-weight: 600;
  color: #6c757d;
}

.question-hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.lines-on-sides {
  display: table;
  text-align: center;
  white-space: nowrap;
}
.lines-on-sides:after, .lines-on-sides:before {
  content: '';
  display: table-cell;
  width: 50%;
  background: -webkit-linear-gradient(transparent 50%, #fff 50%, #fff calc(50% + 1px), transparent calc(50% + 1px));
  background: linear-gradient(transparent 50%, #fff 50%, #fff calc(50% + 2px), transparent calc(50% + 2px));
  background-clip: padding;
  padding: 0 2px;
}
.lines-on-sides:after {
  border-left: 1em solid transparent;
}
.lines-on-sides:before {
  border-right: 1em solid transparent;
}

.carousel-img {
  max-width: 200px;
}

.modal-dialog {
  max-width: 767px !important;
}

.btn.btn-default.btn-admin.btn-create-question {
  margin: 0 auto 50px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  font-size: 22px;
  background: #FA023C;
  border-bottom: none;
  color: #fff;
}

.btn.btn-default.btn-admin.btn-create-question:hover {
  color: #bbb;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
}

.btn-secondary:not([disabled]):not(.disabled):active {
  border-color: transparent !important;
}

.company-title {
  font-size: 1.5em;
  text-transform: uppercase;
}

.btn.btn-delete-option {
  margin-top: 7px;
  font-weight: bold;
}

.btn.btn-add-answer-option {
  float: left;
  bottom: 43px;
  position: relative;
}

.btn.btn-current-session {
  margin-bottom: 15px;
  font-size: 1.5em;
  padding: 7px 20px;
}

.current-session {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

h4.header-stat {
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #000;
}

p.stat {
  text-align: center;
  font-weight: bold;

}

p.stat > span {
  background: #fff;
  padding: 2px 10px;
  border: 1px solid #000;
  color: #FA023C;
  border-radius: 4px;
  font-size: 25px;
}

/* button colors */

.refresh-button{
  background-color: #0C2340 !important;
  border-color: #0C2340 !important;
}

.download-button{
  background-color: #05386B !important;
  border-color: #05386B !important;
}

.start-game-button{
  background-color: #04BD93 !important;
  border-color: #04BD93 !important;
}

.end-game-button{
  background-color: #FA3333 !important;
  border-color: #FA3333 !important;
}

.create-game-button{
  background-color: #05386B !important;
  border-color: #05386B !important;
}

.delete-button{
  background-color: #C90034 !important;
  border-color: #C90034 !important;
}

.edit-button{
  background-color: #647280 !important;
  border-color: #647280 !important;
}

.create-prize-button{
  background-color: #05386B !important;
  border-color: #05386B !important;
}

.submit-button{
  background-color: #004BA3 !important;
  border-color: #004BA3 !important;
}

.update-button{
  background-color: #0B9EA7 !important;
  border-color: #0B9EA7 !important;
}

.choose-image-button{
  background-color: #05386B !important;
  border-color: #05386B !important;
}

.next-wizard-button{
  background-color: #05386B !important;
  border-color: #05386B !important;
}

.previous-wizard-button{
  background-color: #05386B !important;
  border-color: #05386B !important;
}

.creategame-wizard-button{
  background-color: #04BD93 !important;
  border-color: #04BD93 !important;
}




/* alert */

#modalContainer {
	background-color:rgba(0, 0, 0, 0.3);
	position:absolute;
	width:100%;
	height:100%;
	top:0px;
	left:0px;
	z-index:10000;
	/* background-image:url(tp.png); required by MSIE to prevent actions on lower z-index elements */
}

#alertBox {
	position:relative;
  width: 400px;
  margin-top: 100px;
  border: 1px solid #666;
  border-radius: 4px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 20px 30px;
  padding: 25px;
}

#modalContainer > #alertBox {
	position:fixed;
}

#alertBox h1 {
	margin:0;
	font:bold 0.9em verdana,arial;
	background-color:#3073BB;
	color:#FFF;
	border-bottom:1px solid #000;
  padding:2px 0 2px 5px;
  display: none;
}

#alertBox p {
  text-align: center;
  margin-top: 0;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

#alertBox #closeBtn {
	display:block;
	position:relative;
	margin:5px auto;
	padding:8px;
	border:0 none;
	width:90px;
	text-transform:uppercase;
	text-align:center;
	color:#FFF;
	background-color:#FA023C;
	border-radius: 3px;
  text-decoration:none;
  font-weight: 500;
}

#alertBox #closeBtn:focus {
  outline: none;
}

#alertBox #closeBtn:hover {
  color: #bbb;
}

input[data-switch] {
	 display: none;
}
 input[data-switch] + label {
	 width: 56px;
	 height: 24px;
	 background-color: #e3eaef;
	 background-image: none;
	 border-radius: 2rem;
	 cursor: pointer;
	 display: inline-block;
	 text-align: center;
	 position: relative;
	 transition: all 0.1s ease-in-out;
}
 input[data-switch] + label:before {
	 color: #313a46;
	 content: attr(data-off-label);
	 display: block;
	 font-family: inherit;
	 font-weight: bold;
	 font-size: 0.75rem;
	 line-height: 24px;
	 position: absolute;
	 right: 3px;
	 margin: 0 0.21667rem;
	 top: 0;
	 text-align: center;
	 min-width: 1.66667rem;
	 overflow: hidden;
	 transition: all 0.1s ease-in-out;
}
 input[data-switch] + label:after {
	 content: '';
	 position: absolute;
	 left: 4px;
	 background-color: #98a6ad;
	 box-shadow: none;
	 border-radius: 2rem;
	 height: 18px;
	 width: 18px;
	 top: 3px;
	 transition: all 0.1s ease-in-out;
}
 input[data-switch]:checked + label {
	 background-color: #0acf97;
}
 input[data-switch]:checked + label:before {
	 color: #fff;
	 content: attr(data-on-label);
	 right: auto;
	 left: 4px;
}
 input[data-switch]:checked + label:after {
	 left: 34px;
	 background-color: #f1f3fa;
}
 input[data-switch="bool"] + label {
	 background-color: #fa5c7c;
}
 input[data-switch="bool"] + label:before, input[data-switch="bool"]:checked + label:before {
	 color: #fff !important;
}
 input[data-switch="bool"] + label:after {
	 background-color: #f1f3fa;
}
